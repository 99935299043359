import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { firebaseDB } from "../../firebase";
import { collection, query, where, getDocs, } from "firebase/firestore";
import { Text, Badge, Image, Box, ChakraProvider } from "@chakra-ui/react";
import "../../global.css";
import Navbar from "../layout/navbar";
import Footer from "../layout/footer";

function BlogLayout() {
  const { id } = useParams()
  const [blog, setBlog] = useState({
    blogId: '', blogTitle: '', blogContent: ''
  });

  async function getBlog() {
    //
    console.log("firebase called");
    const q = query(collection(firebaseDB, "Blog"), where("blogId", "==", id));
    const querySnapshot = await getDocs(q);
    console.log("Done");
    setBlog(querySnapshot);
    //
  }
  useEffect(() => {
    getBlog();
  }, []);

  return (
    <div className="container-background">
      <div className="container">
        {blog.docs && blog.docs.map((doc, index) => {
          document.title = doc.data().blogTitle;
          // doc.data() is never undefined for query doc snapshots
          return (
            <ChakraProvider>
              <div id={index}>
                {/* <Link href="/blog">
                  <span class="material-symbols-outlined">
                    arrow_back
                  </span>
                </Link> */}
                {/* <Text className="roxborough" fontSize='5xl' mt={10} mb={10}>Shubham Raval</Text> */}
                <Navbar />
                <Text className="rubik-light" fontSize='1xl' mb={2} mt={20}>{doc.data().date}</Text>
                <Text className="roxborough" fontSize='3xl' mb={5}>{doc.data().blogTitle}</Text>
                <Box mb={10}>
                  <Image src={doc.data().blogImage} alt="" htmlWidth="100%" htmlHeight="auto"/>
                </Box>
                <Text className="rubik-light" fontSize='18px' mb={10}>{doc.data().blogContent}</Text>
                <Badge className="rubik-light" id={index} mb={5} colorScheme='yellow' letterSpacing='wider' fontWeight='black'><Text fontSize='1xl'>{doc.data().blogTag}</Text></Badge>
              </div>
            </ChakraProvider>
          )
        })
        }
        <Footer />
      </div>
    </div>

  );
}

export default BlogLayout;