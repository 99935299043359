import {
  ChakraProvider,
  HStack,
  VStack,
  Link,
  Text,
  Button,
  Flex,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import "../../global.css";
import { NavLink } from "react-router-dom";


function Navbar() {

  useEffect(() => {
    const width = window.innerWidth;
    if (width <= 600) {
      const element = document.getElementById("navbarhstack");
      element.style.display = "none";
    } else {
      const element = document.getElementById("navbarId");
      element.style.display = "none";
    }
  }, []);
  // alert(width);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()

  return (
    <ChakraProvider>
      <Flex justifyContent='space-between' className="navbar" alignItems='center' mb={10}>
        <Text fontSize='4xl' className="roxborough"><a href="../">Shubham Raval</a></Text>
        <HStack fontSize='2xl' spacing={8} color='#FF3300' className="rubik-regular" id="navbarhstack">
          <NavLink activeClassName="active" to="../"><Text>Home</Text></NavLink>
          <NavLink activeClassName="active" to="../projects"><Text>Projects</Text></NavLink>
          <NavLink activeClassName="active" to="../blog"><Text>Blog</Text></NavLink>
        </HStack>
        <Button ref={btnRef} id="navbarId" onClick={onOpen} colorScheme='blackAlpha' variant='ghost' size='sm'>
          <span class="material-symbols-outlined">
            lunch_dining
          </span></Button>
      </Flex>
      {/*  */}
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody>
            <DrawerCloseButton />
            <VStack fontSize='2xl' mt={20} spacing={8} color='#FF3300' className="rubik-regular" id="navbarvstack">
              <NavLink activeClassName="active" to="../"><Text>Home</Text></NavLink>
              <NavLink activeClassName="active" to="../projects"><Text>Projects</Text></NavLink>
              <NavLink activeClassName="active" to="../blog"><Text>Blog</Text></NavLink>
            </VStack>
          </DrawerBody>
          <DrawerFooter>
          </DrawerFooter>

        </DrawerContent>
      </Drawer>
      {/*  */}
    </ChakraProvider>);
}

export default Navbar;