import React from "react";
import {
  ChakraProvider,
  Text
} from "@chakra-ui/react";
import "../../global.css"
import Navbar from "../layout/navbar";
import Footer from "../layout/footer";

function Projects() {
  return (
    <ChakraProvider>
      <div className="container-background">
        <div className="container">
          <Navbar></Navbar>
          <Text>Projects will be added soon</Text>
          <Footer></Footer>
        </div>
      </div>
    </ChakraProvider>
  );
}

export default Projects;