import React from 'react';
import '../../global.css';
import {
  ChakraProvider,
  Text,
  Link,
  background
} from '@chakra-ui/react';
import Lottie from 'react-lottie';
import animationData from '../../static/lotties/shapes-floating.json';
import Navbar from '../layout/navbar';
import Footer from '../layout/footer';

function Home() {
  document.title = "Home - shubhamraval.in";

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <ChakraProvider>
      <div className='container-background'>
        <div className='container'>
          {/* <div className='lottie-parent'>
            <Lottie
              options={defaultOptions}
              height={200}
              width={200}
            />
          </div> */}
          {/* <Text fontSize='5xl' className='roxborough' mb={5}>Shubham Raval</Text> */}
          <Navbar />
          <Text fontSize='2xl' className='home-p rubik-light' mb={5}>
            Hello there, world! I'm Shubham Raval, a software engineer who's always hungry for innovation and delicious food (but mostly food). When I'm not busy writing code, you can find me brainstorming my next big business idea. I've been known to code with one hand and hold a sandwich in the other, so you could say that software and food are my two main passions in life. But don't let my love for food fool you – I'm a force to be reckoned with in the world of tech and entrepreneurship. So whether you're hungry for a killer software solution or just some good eats, I'm your go-to guy! please feel free to{" "}
            <strong>
              <Link href="mailto:shubhamdraval@gmail.com">contact me</Link>{" "}
            </strong>
            to discuss your next project or swap restaurant recommendations.
          </Text>
          <br />
          <Text fontSize='2xl' className='rubik-regular' mb={2}>You can also ping me at</Text>
          <Link href="https://github.com/shubhamdraval" target="_blank">
            <Text fontSize='1xl' className='rubik-regular'>GitHub</Text>
          </Link>
          <Link href="https://www.linkedin.com/in/its-shubhamraval/" target="_blank">
            <Text fontSize='1xl' className='rubik-regular'>Linkedin</Text>
          </Link>
          <Link href="https://www.instagram.com/iamshubhamraval/" target="_blank">
            <Text fontSize='1xl' className='rubik-regular'>Instagram</Text>
          </Link>
          <Footer />
        </div>
      </div>
    </ChakraProvider>
  );
}

export default Home;
