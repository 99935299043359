import React from "react";
import {
  ChakraProvider,
  Text,
} from "@chakra-ui/react";

function Footer() {
  return (
    <ChakraProvider>
      <Text textAlign='center' className="rubik-regular" color='#999999' mt={40}>Shubham Raval © 2001-2022.</Text>
    </ChakraProvider>
  );
}

export default Footer