import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  ChakraProvider
} from '@chakra-ui/react'
import React from "react";
import Navbar from '../layout/navbar';

function NotFound() {

  return (
    <ChakraProvider>
      <div className='container-background'>
        <div className='container'>
          <Navbar />
          <Alert
            status='warning'
            variant='subtle'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            textAlign='center'
            height='200px'
          >
            <AlertIcon boxSize='40px' mr={0} />
            <AlertTitle mt={4} mb={1} fontSize='lg'>
              404 Error
            </AlertTitle>
            <AlertDescription maxWidth='sm'>
              Sorry, but the page you are trying to access does not exist.
            </AlertDescription>
          </Alert>
        </div>
      </div>
    </ChakraProvider>);
}

export default NotFound;