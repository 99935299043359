import React, { useEffect, useState } from "react";
import './App.css';
import Blog from './components/blog/blog';
import Home from './components/home/home';
import AddBlog from "./components/blog/addBlog";
import Login from "./components/blog/login";
import NotFound from "./components/error/notFound";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import BlogLayout from "./components/blog/blogLayout";
import Projects from "./components/home/projects";

function App() {
  const [loginState, setLoginState] = useState(JSON.parse(localStorage.getItem('loginState')) || "");

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home></Home>} />
          <Route path="/projects" element={<Projects></Projects>} />
          <Route path="/blog" element={<Blog></Blog>} />
          <Route path="/blog/add-blog" element={
            loginState == "loggedin" ? <AddBlog /> : <Navigate to="/" replace />
          } />
          <Route path="/blog/login" element={<Login></Login>} />
          <Route path="/blog/:id" element={<BlogLayout></BlogLayout>} />
          <Route path="*" element={<NotFound></NotFound>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
