import React, { useEffect, useState } from "react";
import { collection, getDocs, orderBy } from "firebase/firestore";
import { firebaseDB } from "../../firebase";
import {
  ChakraProvider,
  Text,
  VStack,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Badge,
  Heading,
  Link,
  Fade,
  ScaleFade,
  Slide,
  SlideFade,
  useDisclosure
} from '@chakra-ui/react';
import "../../global.css";
import Navbar from "../layout/navbar";
import Footer from "../layout/footer";



function Blog() {
  document.title = "Blog | shubhamraval.in";

  const { isOpen, onOpen } = useDisclosure()
  const [blogs, setBlogs] = useState([]);
  async function getBlogs() {
    console.log("firebase called");
    const querySnapshot = await getDocs(collection(firebaseDB, "Blog"));
    console.log("Done");
    setBlogs(querySnapshot);
    // return querySnapshot;
  }
  useEffect(() => {
    getBlogs();
    onOpen();
  }, []);

  return (
    <ChakraProvider>
      <div className="container-background">
        <div className="container">
          <Navbar />
          {/* <Text fontSize='5xl' align='left' mb={10} className="roxborough">Shubham Raval</Text> */}
          <Text fontSize='2xl' align='left' color='GrayText' className="rubik-regular">The Blog &nbsp; <span class="material-symbols-outlined" onClick={()=>{ window.open() }}>
            rss_feed
          </span></Text>
          <Text fontSize='1xl' align='left' color='GrayText' className="rubik-regular">I sometimes write about software, entrepreneurship and food.</Text>
          <VStack spacing={5} mt={5} alignItems='left'>
            {blogs.docs && blogs.docs.map((doc, index) => {
              // doc.data() is never undefined for query doc snapshots
              return (
                // <ScaleFade initialScale={0.9} in={isOpen} >
                <Card className="pointer" key={index} maxWidth={620} variant='outline' onClick={() => { window.open(`/blog/${doc.data().blogId}`, '_self') }}>
                  <CardBody>
                    <Text fontSize='sm' className="rubik-light" mb={2} color='#9e9e9e'>{doc.data().date}</Text>
                    <Text fontSize='2xl' mb={2.5}>
                      <Link className="roxborough" href={`/blog/${doc.data().blogId}`}>
                        {doc.data().blogTitle}
                      </Link>
                    </Text>
                    <Text isTruncated className="rubik-regular" maxWidth={600}>{doc.data().blogContent}</Text>
                    <Badge colorScheme='yellow' className="rubik-light" mt={2.5} letterSpacing='wider' fontWeight='black'>{doc.data().blogTag}</Badge>
                  </CardBody>
                </Card>
                // </ScaleFade>
              )
              console.log(doc.id, " => ", doc.data().blogContent);
            })}
          </VStack>
          <Footer />
        </div>
      </div>
    </ChakraProvider>
  );
}

export default Blog;