import React, { useState } from "react";
import {
  ChakraProvider,
  useToast,
  Text,
  Input,
  VStack,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  Spinner,
  Select
} from '@chakra-ui/react'
import { doc, setDoc } from "firebase/firestore";
import { firebaseDB } from "../../firebase";
import "../../global.css";
import Trix from "trix"
import Navbar from "../layout/navbar";
import Footer from "../layout/footer";


function AddBlog() {
  const toast = useToast()

  //useState hooks for add blog input fields.
  const [blogTitle, setBlogTitle] = useState("");
  const [blogId, setBlogId] = useState("");
  const [blogImage, setBlogImage] = useState("");
  const [blogContent, setBlogContent] = useState("");
  const [blogDate, setBlogDate] = useState(new Date().toLocaleDateString());
  const [blogTag, setBlogTag] = useState("");

  //UseState for spinner
  const [spinner, setSpinner] = useState(false);

  //Method for posting blog.
  async function postBlog() {
    let errorMessage = null;
    if (blogTitle == "" || blogId == "" || blogContent == "" || blogTag == "") {
      toast({
        title: 'Error',
        description: "Fill the required details.",
        status: 'info',
        duration: 9000,
        variant: 'subtle',
        isClosable: true,
      })
      return;
    }
    //alert(firebaseDB.app.name);
    setSpinner(true);
    await setDoc(doc(firebaseDB, "Blog", new Date().toLocaleTimeString()), {
      blogTitle: blogTitle,
      blogId: blogId,
      blogContent: blogContent,
      date: blogDate,
      blogTag: blogTag,
      blogImage: blogImage,
      blogTime: new Date().toISOString(),
    }).catch(
      (error) => {
        toast({
          title: 'Noooooooooooooo',
          description: error.message,
          status: 'warning',
          duration: 9000,
          variant: 'subtle',
          isClosable: true,
        })
        errorMessage = error.message;
        setSpinner(false);
      }
    );
    if (errorMessage) {
      return;
    }
    setBlogTitle('');
    setBlogId('');
    setBlogImage('');
    setBlogContent('');
    setSpinner(false);
    toast({
      title: 'Yoooooooooooooo',
      description: "Blog added successfully",
      status: 'success',
      duration: 9000,
      variant: 'subtle',
      isClosable: true,
    })
    //console.log('done');
  }

  return (
    <>
      <ChakraProvider>
        <div className="container-background">
          <div className="container">
            <Navbar />
            <VStack
              className="rubik-regular"
              spacing={4}
              align='start'
            >
              <Text fontSize='4xl' textAlign='center' className="roxborough">Add Blog ⚡</Text>

              <FormControl isRequired>
                <FormLabel>Title</FormLabel>
                <Input placeholder='Ex. Scaling with common sense'
                  value={blogTitle}
                  onChange={(e) => {
                    setBlogTitle(e.target.value);
                  }}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Blog ID</FormLabel>
                <Input placeholder='Ex. scaling-with-common-sense'
                  value={blogId}
                  onChange={(e) => {
                    setBlogId(e.target.value);
                  }}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Content</FormLabel>
                <Textarea placeholder='Ex. Over the last year, owing to the unexpected rally in capital markets ...'
                  value={blogContent}
                  onChange={(e) => {
                    setBlogContent(e.target.value);
                  }}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Image Link</FormLabel>
                <Input placeholder='Ex. https://bit.ly/dan-abramov'
                  value={blogImage}
                  onChange={(e) => {
                    setBlogImage(e.target.value);
                  }}
                />
              </FormControl>

              <FormControl isReadOnly>
                <FormLabel>Date</FormLabel>
                <Input placeholder='Date'
                  value={blogDate}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Tag</FormLabel>
                <Select value={blogTag} onChange={(e) => { setBlogTag(e.target.value) }}>
                  <option value='Software'>Software</option>
                  <option value='Entrepreneurship'>Entrepreneurship</option>
                  <option value='Food'>Food</option>
                </Select>
              </FormControl>

              {/* <trix-editor input="x"></trix-editor> */}


              <Button colorScheme='purple'
                onClick={() => { postBlog(); }}>{spinner ? <Spinner /> : "Post"}</Button>
            </VStack>
            <Footer />
          </div>
        </div>
      </ChakraProvider>
    </>
  );
}

export default AddBlog;