// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBtSIiJ8rxpyFnnyw5uE7Kc2aJgOLaXuvA",
  authDomain: "shubhamdraval-blog.firebaseapp.com",
  projectId: "shubhamdraval-blog",
  storageBucket: "shubhamdraval-blog.appspot.com",
  messagingSenderId: "696256464349",
  appId: "1:696256464349:web:e702a07dbbf71c329aa234",
  measurementId: "G-716GNMVDJF"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseDB = getFirestore(firebaseApp);
const analytics = getAnalytics(firebaseApp);