import React, { useState, useEffect } from "react";
import {
  ChakraProvider,
  Text,
  Input,
  VStack,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { firebaseApp } from "../../firebase";
import "../../global.css";
import Navbar from "../layout/navbar";
import Footer from "../layout/footer";

function Login() {

  const auth = getAuth(firebaseApp);

  //useState hook For Password visibility.
  const [show, setShow] = useState(false)
  const handlePasswordShowClick = () => setShow(!show)

  //useState hook for login cycle
  const [loginState, setLoginState] = useState(JSON.parse(localStorage.getItem('loginState')) || "login");

  //loginUser method definition
  const [clientUserEmail, setclientUserEmail] = useState(JSON.parse(localStorage.getItem('clientUserEmail')) || "");
  const [clientUserPassword, setClientUserPassword] = useState("");

  useEffect(() => {
    localStorage.setItem('loginState', JSON.stringify(loginState));
    localStorage.setItem('clientUserEmail', JSON.stringify(clientUserEmail));
    localStorage.setItem('clientUserPassword', JSON.stringify(clientUserPassword));
  }, [loginState]);

  const loginUser = () => {
    signInWithEmailAndPassword(auth, clientUserEmail, clientUserPassword)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        console.log(user);
        setLoginState('loggedin');
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setLoginState('loginfailed');
      });
  }
  document.title = "Login";
  if (loginState == 'loggedin') {
    return (
      <>
        <ChakraProvider>
          <div className="container-background">
            <div className="container rubik-regular">
              <Navbar />
              <Alert
                status='success'
                variant='subtle'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                textAlign='center'
                height='150px'
              >
                <AlertIcon boxSize='40px' mr={0} />
                <AlertTitle mt={4} mb={1} fontSize='lg'>
                  Yayyyyy!
                </AlertTitle>
                <AlertDescription maxWidth='sm'>
                  You are logged in as {clientUserEmail}.
                </AlertDescription>
              </Alert>
              <Footer />
            </div>
          </div>
        </ChakraProvider>
      </>
    );
  } else if (loginState == 'loginfailed') {
    return (
      <>
        <ChakraProvider>
          <div className="container-background">
            <div className="container rubik-regular">
              <Navbar />
              <Alert
                status='error'
                variant='subtle'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                textAlign='center'
                height='auto'
              >
                <AlertIcon boxSize='40px' mr={0} />
                <AlertTitle mt={4} mb={1} fontSize='lg'>
                  Nayyyyy!
                </AlertTitle>
                <AlertDescription maxWidth='sm'>
                  Failed to log in as {clientUserEmail}.
                </AlertDescription>
                <Button
                  colorScheme='blackAlpha'
                  mt='5'
                  onClick={() => { setLoginState('login') }}>Retry</Button>
              </Alert>
              <Footer />
            </div>
          </div>
        </ChakraProvider>
      </>
    );
  } else {
    return (
      <>
        <ChakraProvider>
          <div className="container-background">
            <div className="container">
              <Navbar />
              <VStack
                className="rubik-regular"
                margin={10}
                spacing={4}
                align='start'
              >
                <Text fontSize='4xl' className="roxborough" textAlign='center'>Login</Text>

                <FormControl isRequired>
                  <FormLabel>User Email</FormLabel>
                  <Input
                    value={clientUserEmail}
                    onChange={(e) => setclientUserEmail(e.target.value)}
                  ></Input>
                </FormControl>

                <FormControl>
                  <FormLabel>Password</FormLabel>
                  <InputGroup size='md'>
                    <Input
                      pr='4.5rem'
                      type={show ? 'text' : 'password'}
                      value={clientUserPassword}
                      onChange={(e) => setClientUserPassword(e.target.value)}
                    />
                    <InputRightElement width='4.5rem'>
                      <Button h='1.75rem' size='sm' onClick={handlePasswordShowClick}>
                        {show ? 'Hide' : 'Show'}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                <Button colorScheme='purple' onClick={loginUser}>Login</Button>
              </VStack>
              <Footer />
            </div>
          </div>
        </ChakraProvider>
      </>
    );
  }
}

export default Login;